<template>
  <b-modal
    :id="id"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    cancel-title="Hủy bỏ"
    centered
    no-close-on-backdrop
    :title="title"
    @ok="accept($event)"
    @hidden="resetData"
  >
    <validation-observer ref="rule">
      <b-form>

        <b-form-group>
          <label for="code">Tên tổ chức kiểm định<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="bussinessName"
            rules="required"
            :custom-messages="bussinessName"
          >
            <b-form-input
              v-model="dataInput.bussinessName"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label>Email<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="email|required"
            :custom-messages="mail"
          >
            <b-form-input
              v-model="dataInput.email"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="taxNumber"
          >
            <label for="code">Mã số thuế<span class="required">(*)</span></label>
            <b-form-input
              v-model="dataInput.taxNumber"
              type="number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      listEquipment: [],
      required,
      bussinessName: {
        required: 'Tên tổ chức là bắt buộc',
      },
      mail: {
        required: 'Email là bắt buộc',
        email: 'Email không hợp lệ',
      },
      taxNumber: {
        email: 'Mã số thuế là bắt buộc',
      },
      dataInput: {
        bussinessName: '',
        email: '',
        taxNumber: '',
      },

    }
  },

  methods: {
    accept(bvModalEvt) {
      this.dataInput.accreditationId = this.$route.params.id
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        bussinessName: '',
        email: '',
        taxNumber: '',
      }
    },
  },
}

</script>

<template>
  <b-modal
    :id="id"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    cancel-title="Hủy bỏ"
    centered
    no-close-on-backdrop
    :title="title"
    @ok="accept($event)"
    @hidden="resetData"
  >
    <validation-observer ref="rule">
      <b-form>

        <b-form-group>
          <label for="code">Tên kiểm định viên<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="name"
          >
            <b-form-input
              v-model="dataInput.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label>Tổ chức kiểm định<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="bussiness"
            rules="required"
            :custom-messages="bussiness"
          >
            <v-select
              v-model="dataInput.bussinessId"
              :reduce="item => item.id"
              :class="{'is-invalid':errors.length > 0 }"
              label="name"
              :options="listBussiness || []"
              placeholder="Lựa chọn tổ chức kiểm định"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    listBussiness: {
      type: Array,
      default: () => ([]),
    },
    businessId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listEquipment: [],
      required,
      name: {
        required: 'Tên kiểm định viên là bắt buộc',
      },
      bussiness: {
        required: 'Tổ chức kiểm định là bắt buộc',
      },
      dataInput: {
        name: '',
        bussinessId: '',
      },

    }
  },
  watch: {
    businessId(val) {
      if (val) {
        this.dataInput.bussinessId = val
      }
    },
  },

  methods: {
    accept(bvModalEvt) {
      this.dataInput.accreditationId = this.$route.params.id
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        name: '',
        bussinessId: '',
      }
    },
  },
}

</script>
